import React, { useEffect, useRef, useState } from 'react'
import "./XlsxTable.css"
import { read, utils } from 'xlsx';

interface President {
  Name: string;
  Index: number;
}

interface XlsxTableProp {
  width: number;
  height: number;
}

const XlsxTable: React.FC<XlsxTableProp> = ({width, height}) => {

  const url5 = "public/plugins/ssl-escapadetimeline-panel/img/ICON_SOE_2024Test.xlsx";
  
  const [pres, setPres] = useState<any[]>([]);
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);


  const tableRef = useRef<HTMLDivElement>(null);

  const getBGColorSun = (value: number) => {
    if (value === 1) {
      return 'black';
    }
    else if (value === 0) {
      return 'yellow';
    }
    return 'gray';
  };

  const getBGColorSAA = (value: number) => {
    if (value === 1) {
      return 'rgb(62,94,140)';
    }
    else if (value === 0) {
      return 'white';
    }
    return 'gray';
  };

  const getBGColorShift = (value: number) => {
    console.log("value", value)
    if (value === 1) {
      return 'rgb(246,185,47)';
    }
    else if (value === 2) {
      return 'rgb(0,12,89)';
    }
    return 'gray';
  };

  /* Fetch and update the state once */
  useEffect(() => { (async() => {
    /* Download from https://docs.sheetjs.com/prs.numbers */
    const f = await fetch(url5);
    const ab = await f.arrayBuffer();

    /* parse */
    const wb = read(ab);

    /* generate array of president from the first worsheet */
    const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    const data: President[] = utils.sheet_to_json<President>(ws); // generate objects

    /* update state */
    setPres(data); // update stte
  })(); }, []);

  useEffect(() => {
    if (pres.length > 0) {
      const now = new Date();
      const nowPDT = new Date(now.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));

      const upcomingEvent = pres.find(row => {
        const rowDatePDT = new Date(new Date(row.__EMPTY_10).toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
        return rowDatePDT > nowPDT;
      });
      
      if (upcomingEvent) {
        const index = pres.indexOf(upcomingEvent) - 1;
        setHighlightedRow(index);
        const rowElement = document.getElementById(`row-${index}`);
        
        if (rowElement && tableRef.current) {
          const containerHeight = tableRef.current.clientHeight;
          const rowPosition = rowElement.offsetTop;
          const scrollPosition = rowPosition - containerHeight / 2 + rowElement.clientHeight / 2;
          tableRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        }
      }

      // Add past event class
      pres.forEach((row, index) => {
        const rowDatePDT = new Date(new Date(row.__EMPTY_10).toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
        if (rowDatePDT < nowPDT) {
          const rowElement = document.getElementById(`row-${index-1}`);
          if (rowElement) {
            rowElement.classList.add('past-event');
          }
        }
      });

    }
  }, [pres]);

  return (
    <div ref={tableRef} className={`Xlsx-box`} style={{ width: width , height: height, overflow:'auto' }}>
      {/* <b>Sheet Names</b><br/> */}
      {/* <ol start={0}>{names.map(n => (<li>{n}</li>))}</ol> */}
      <table>
        {/* The `thead` section includes he table header row */}
        <thead>
          <tr>
            <th>Event #</th>
            <th>Sun</th>
            <th>SAA</th>
            <th>DOY</th>
            <th>Date/Time (UTC)</th>
            <th>Event Description/Procedure Name</th>
            <th>Proc. #</th>
            <th>Additional Event Info</th>
            <th>Comments</th>
            <th>MET (d h:m)</th>
            <th>Orb. #</th>
            <th>Local</th>
            <th>Shift</th>
            {/* <th>Row Number</th> */}
          </tr>
        </thead>
        {/* The `tbody` section includes the data rows */}
        <tbody>
          {/* generate row (TR) for each president */}
          {pres.slice(1).map((row, index) => (
            <tr key={index} id={`row-${index}`} style={highlightedRow === index ? { backgroundColor: '#03688a' } : {}}>
              {/* Generate cell (TD) for name / idex */}
              <td>{row['ICON_SOE_2020105.xlsx']}</td>
              <td 
              style={{ backgroundColor: getBGColorSun(row.__EMPTY) }}>
                {/* {row.__EMPTY} */}
                </td>
              <td style={{ backgroundColor: getBGColorSAA(row.__EMPTY_1) }}>
                {/* {row.__EMPTY_1} */}
                </td>
              <td>{row.__EMPTY_2}</td>
              <td>{row.__EMPTY_3}</td>
              <td>{row.__EMPTY_4}</td>
              <td>{row.__EMPTY_5}</td>
              <td>{row.__EMPTY_6}</td>
              <td>{row.__EMPTY_7}</td>
              <td>{row.__EMPTY_8}</td>
              <td>{row.__EMPTY_9}</td>
              <td>{row.__EMPTY_10}</td>
              <td style={{ backgroundColor: getBGColorShift(row.__EMPTY_11), textAlign:'center' }} >
                {row.__EMPTY_11}
                </td>
              {/* <td>{row.__rowNum__}</td> */}

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default XlsxTable;
