import React from 'react';
import { PanelProps } from '@grafana/data';
import {SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { PanelDataErrorView } from '@grafana/runtime';
import XlsxApp from './XlsxTable';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      fontFamily: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

export const SimplePanel: React.FC<Props> = ({options, data, width, height, fieldConfig, id }) => {

  const styles = useStyles2(getStyles);

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  return (
    <div       
    className={cx(
      styles.wrapper,
      css`
        width: ${width}px;
        height: ${height}px;
      `
    )}>

      <XlsxApp width={width} height={height}/>
    </div>
   ); 
};
